<template>
    <div class="rechargeManagement-list">
        <app-header :title="title" :isShowBack="false"></app-header>
        <div class="rechargeManagement-row">平台营销账号余额<span>{{totalAmount}}</span>元</div>
        <div class="rechargeTips">说明：提交充值订单后24H内有效</div>
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                title: '充值管理',
                totalAmount: 0.00,
                opt: {
                    search: [{
                        key: "memberUserName",
                        label: "商家名称",
                    },{
                        key: "rechargeTime",
                        label: "充值时间",
                        type: "data-range"
                    }, ],
                    columns: [{
                            label: "商家名称",
                            key: "bizUserName"
                        },
                        {
                            label: "商家证件号码",
                            key: "cardNoStr"
                        },
                        {
                            label: "保证金余额（元）",
                            key: "rechargeBalance"
                        },
                        {
                            label: "最新充值单号",
                            key: "orderNo"
                        },
                        {
                            label: "最新充值时间",
                            key: "startTime"
                        },
                        {
                            label: "充值地址（浏览器打开）",
                            key: "url",
                            opt: {
                                isUserPopover: true
                            }
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-but",
                            opt: {
                                list: [{
                                    label: "充值记录",
                                    on(row, cb) {
                                        _this.$router.push('/main/rechargeManagement/detail/' + row.bizUserId);
                                    }
                                }]
                            }
                        }
                    ],
                    buttons: []
                },
            };
        },
        created() {},
        deactivated() {},
        activated() {
            this.getTotalAmount();
        },

        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    startTime: opt.searchForm['rechargeTime'] ? opt.searchForm['rechargeTime'][0] : null,
                    endTime: opt.searchForm['rechargeTime'] ? opt.searchForm['rechargeTime'][1] : null,
                    ...opt.searchForm
                };
                delete dto.rechargeTime;
                this.post("/mall-service/allinpay/v1/recharge/group/page", dto, {
                    isUseResponse: false
                }).then(res => {
                    if(res.data && res.data.length > 0) {
                        res.data.forEach(item => {
                            item.cardNoStr = item.cardNo ? item.cardNo.slice(0,4) + '*********' + item.cardNo.slice(item.cardNo.length - 4,item.cardNo.length) : '--';
                            item.url = item.url ? item.url : '--';
                            item.startTime = item.startTime ? item.startTime : '--';
                            item.orderNo = item.orderNo ? item.orderNo : '--';
                            item.rechargeBalance = item.rechargeBalance ? Number(item.rechargeBalance).toFixed(2) : '0.00'
                        });
                    }
                    opt.cb(res);
                });
            },
            getTotalAmount() {
                this.post('mall-service/allinpay/v1/recharge/balance',{},{
                    isUseResponse: false
                }).then(res => {
                    if(res.code == 0) {
                        this.totalAmount = res.data.totalAmount ? Number(res.data.totalAmount).toFixed(2) : '0.00';
                    }
                })
            },
        }
    };
</script>
<style lang="scss" scoped>
.rechargeManagement-list {
    height: calc(100% - 124px);
}
.rechargeManagement-row {
    padding-bottom: 5px;
}
.rechargeManagement-row span {
    font-size: 20px;
    padding: 0 6px;
}
.rechargeTips{
    padding-bottom: 15px;
    font-size: 14px;
    color: orange;
}
</style>